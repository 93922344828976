import {
  baseColumnLayout,
  baseLayout,
  Gradients,
  LayoutProps,
  styled,
  TypographyProps,
  debug,
  theme,
} from '@easy-expense/ui-theme';
import { Layout, OpenSans } from '@easy-expense/ui-web-core';
import { DoNotFixMeIAmAny } from '@easy-expense/utils-typescript';
import React from 'react';

type ButtonProps = LayoutProps & {
  onClick?: (arg0: DoNotFixMeIAmAny) => void;
  inactiveOnClick?: (arg0: DoNotFixMeIAmAny) => void;
  content?: string;
  text?: TypographyProps;
  wait?: number;
  active?: boolean;
  gradient?: string[];
  variant?: 'bold';
};

const StyledButton = styled('button')<ButtonProps>`
  ${baseLayout}
  ${baseColumnLayout}
  ${debug('rebeccapurple')}
  border: none;
  cursor: pointer;
`;

const DefaultButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const {
    children,
    content,
    text,
    wait,
    py,
    px,
    active = true,
    grow,
    radius,
    bg,
    shadow,
    gradient,
    variant,
    onClick,
    inactiveOnClick,
    ...rest
  } = props;

  const finalOnClick = active && onClick ? onClick : inactiveOnClick;
  const contentComponent = content ? (
    <OpenSans.Custom
      color={active ? 'primary' : 'secondary'}
      size={variant === 'bold' ? 'm-18' : 's-16'}
      weight={'bold-700'}
      {...text}
    >
      {content}
    </OpenSans.Custom>
  ) : (
    <Layout.Row align>{children}</Layout.Row>
  );

  return (
    <StyledButton
      py={py ?? (variant === 'bold' ? 16 : 8)}
      px={px ?? 20}
      bg={bg}
      radius={radius ?? (variant === 'bold' ? 8 : 100)}
      shadow={shadow}
      gradient={gradient}
      variant={variant}
      {...rest}
      onClick={finalOnClick}
    >
      {contentComponent}
    </StyledButton>
  );
};

const PrimaryButton = styled(DefaultButton).attrs(
  ({ active = true, text }: { active: boolean; text: TypographyProps }) => ({
    gradient: active ? Gradients.blue : undefined,
    bg: active ? 'brandPrimary' : 'buttonBlackInactive',
    text: {
      ...text,
      color: 'inverse',
    },
  }),
)`
  color: ${theme.colors.buttonWhite};
  size: 16;
  font-weight: 700;
  &:hover {
    background-color: ${() => theme.colors.brandPrimaryDark};
  }
`;

const DestructiveButton = styled(DefaultButton).attrs(({ active = true }) => ({
  bg: active ? 'destructiveXLight' : 'buttonBlackInactive',
  text: {
    color: 'destructiveDark',
  },
}))`
  &:hover {
    background-color: ${() => theme.colors.destructiveLight};
  }
`;

const SecondaryButton = styled(PrimaryButton).attrs(
  ({ active = true, text }: { active: boolean; text: TypographyProps }) => ({
    gradient: active ? Gradients.blue : undefined,
    bg: active ? 'brandPrimaryXLight' : 'buttonBlackInactive',
    text: {
      ...text,
      color: 'brandPrimary',
    },
  }),
)`
  &:hover {
    background-color: ${() => theme.colors.brandPrimaryLight};
  }
`;

const WhiteButton = styled(DefaultButton).attrs(
  ({ active = true, text }: { active: boolean; text: TypographyProps }) => ({
    border: [1.25, 'solid', 'brandPrimary'],
    bg: active ? 'buttonWhite' : 'buttonWhiteInactive',
    text: {
      ...text,
      color: 'brandPrimary',
      weight: 'bold-700',
    },
  }),
)``;

const IconButton = styled(PrimaryButton).attrs()`
  border-radius: 100%;
  background-color: ${theme.colors.buttonWhite};
  &:hover {
    opacity: 0.6;
  }
`;

export const Button = {
  Default: DefaultButton,
  Primary: PrimaryButton,
  Secondary: SecondaryButton,
  Destructive: DestructiveButton,
  White: WhiteButton,
  Icon: IconButton,
};
