import { useOwnWorkspaceMemberships } from '@easy-expense/data-firestore-client';
import { fuego } from '@easy-expense/firebase-app';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner.component';
import {
  OnboardingActionColumn,
  OnboardingBackdrop,
} from '../components/Onboarding/OnboardingBackdrop.component';
import { SignUpInvited } from '../components/SignUpInvited.form';

export const InviteAccept: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = fuego.auth().currentUser;
  const workspaces = useOwnWorkspaceMemberships();

  const workspaceName = params.get('workspaceName') ?? '';
  const email = params.get('email') ?? '';

  React.useEffect(() => {
    if (workspaces.length && navigate) {
      navigate?.('/accepted');
    }
  }, [workspaces, navigate]);

  function renderInviteAction() {
    if (currentUser?.emailVerified) {
      return (
        <Layout.Column center>
          <LoadingSpinner size={48} />

          <Spacer.Vertical size={24} />

          <OpenSans.Primary size="l-20" center>
            Creating Your Account...
          </OpenSans.Primary>
        </Layout.Column>
      );
    }

    return (
      <Layout.Column>
        <OpenSans.Primary weight="regular-400" size={'xl-28'} center>
          <p>
            Sign up to join the
            <b>{workspaceName ? ` ${workspaceName} ` : ' '}</b>
            workspace
          </p>
        </OpenSans.Primary>
        <SignUpInvited email={email} />
      </Layout.Column>
    );
  }

  return (
    <OnboardingBackdrop>
      <OnboardingActionColumn width={430}>
        <Layout.Column align>{renderInviteAction()}</Layout.Column>
      </OnboardingActionColumn>
    </OnboardingBackdrop>
  );
};
