import {
  canModifyUsers,
  disableMember,
  grantRole,
  useCachedMembers,
  useCurrentWorkspace,
} from '@easy-expense/data-firestore-client';
import { UserRole, UserRoleSchema } from '@easy-expense/data-schema-v2';
import { getTranslation, formatRelativeTime } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Alert } from 'react-native';

import { PermissionsSelector } from './PermissionsSelector.componet';
import { WorkspaceRow, useWorkspaceRows } from './useWorkspaceRows';
import { auth } from '../../firebase/app';
import { Badge } from '../Shared/Badge.component';
import { Modal } from '../Shared/Modal.component';

type ContentChoice = 'delete' | 'options' | 'disable' | false;

export const WorkspaceMemberTable: React.FC<React.PropsWithChildren<object>> = ({}) => {
  const workspaceRows = useWorkspaceRows();
  const members = useCachedMembers();

  const [user] = useAuthState(auth);
  const self = members?.find((mem) => mem.userID === user?.uid);
  const owner = useCurrentWorkspace()?.createdBy;

  const [modalOpen, setModalOpen] = React.useState<ContentChoice>(false);
  const [rowSelected, setRowSelected] = React.useState<WorkspaceRow | null>(null);
  const [selectedRowPermission, setSelectedRowPermission] = React.useState<UserRole>();

  function onRowClick(row: WorkspaceRow) {
    setRowSelected(row);
    const member = members?.find((mem) => mem.userID === row.userID);
    if (
      self &&
      member?.userID !== owner &&
      canModifyUsers(self, owner) &&
      self.key !== member?.key
    ) {
      setSelectedRowPermission(member?.role);
      setModalOpen('options');
    }
  }

  function startDisable() {
    try {
      disableMember(rowSelected?.userID ?? '');
      setModalOpen(false);
    } catch (e) {
      Alert.alert('There was an error disabling the user');
    }
  }
  function startGrant() {
    if (!selectedRowPermission) {
      return;
    }

    try {
      grantRole(selectedRowPermission, rowSelected?.userID ?? '');
    } catch (e) {
      Alert.alert('There was an error granting the user');
    }

    setModalOpen(false);
  }

  const columns = React.useMemo<MRT_ColumnDef<WorkspaceRow>[]>(
    () => [
      {
        id: 'displayName',
        header: getTranslation('Name'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Name')}</OpenSans.Secondary>
        ),
        accessorFn: (row) => row.displayName,
        maxSize: 10,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-16"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.displayName}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        id: 'role',
        header: getTranslation('Role'),
        accessorFn: (row) => row.role,
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Role')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary size="s-16">{row.original.role}</OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        id: 'email',
        header: getTranslation('Email'),
        accessorFn: (row) => row.email,
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Email')}</OpenSans.Secondary>
        ),
        size: 300,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-16"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {row.original.email}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
      {
        id: 'status',
        header: getTranslation('Status'),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Status')}</OpenSans.Secondary>
        ),
        accessorFn: (row) => row.status,
        size: 100,
        Cell: ({ row }) => {
          const status = row.original.status;
          return (
            <Layout.Row style={{ width: 150 }}>
              <Badge
                color={status === 'Active' ? theme.colors.success : theme.colors.warning}
                text={status}
              />
            </Layout.Row>
          );
        },
      },
      {
        id: 'lastActive',
        header: getTranslation('Last Active'),
        filterVariant: 'date-range',
        filterFn: 'betweenInclusive',
        sortingFn: 'datetime',
        accessorFn: (row) => new Date(row.lastActive),
        Header: () => (
          <OpenSans.Secondary size={'s-16'}>{getTranslation('Last Active')}</OpenSans.Secondary>
        ),
        size: 100,
        Cell: ({ row }) => {
          return (
            <Layout.Column>
              <OpenSans.Primary
                size="s-16"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  textAlign: 'left',
                }}
              >
                {`${formatRelativeTime(new Date(row.original.lastActive))}`}
              </OpenSans.Primary>
            </Layout.Column>
          );
        },
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data: workspaceRows,
    initialState: {
      showGlobalFilter: true,
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 25 },
    },
    icons: {
      CloseIcon: () => (
        <Icon
          name="close"
          size={18}
          color={theme.colors.secondary}
          style={{ padding: 0, margin: 0 }}
        />
      ),
      SearchIcon: () => (
        <Icon name="search" size={18} color={theme.colors.secondary} style={{ marginRight: 8 }} />
      ),
      FilterAltIcon: () => <Icon name="funnel" size={18} color={theme.colors.secondary} />,
      ViewColumnIcon: () => <Icon name="build-outline" size={18} color={theme.colors.secondary} />,
    },
    enableColumnActions: false,
    columnFilterDisplayMode: 'subheader',
    getRowId: (row) => row.userID ?? 'invite',
    enableDensityToggle: false,
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          color: theme.colors.secondary,
          fontWeight: 'regular',
        },
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => onRowClick(row.original),
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: '',
      },
    },
    renderTopToolbar: ({ table }) => {
      return (
        <Layout.Row py>
          <MRT_GlobalFilterTextField
            table={table}
            sx={{
              borderColor: theme.colors.inputBorder,
              '& .MuiInputBase-root': {
                backgroundColor: theme.colors.inputBackground,
                borderRadius: 2,
                borderColor: theme.colors.success,
                width: 500,
                paddingRight: 1,
              },
            }}
          />
          <Spacer.Horizontal size={6} />
          <MRT_ShowHideColumnsButton table={table} />
          <Spacer.Horizontal size={6} />

          <MRT_ToggleFiltersButton table={table} />
        </Layout.Row>
      );
    },
  });

  return (
    <Layout.Column>
      <MaterialReactTable table={table} />
      <Modal showModal={!!modalOpen} setShowModal={() => setModalOpen(false)}>
        {rowSelected?.status === 'Invited'
          ? (() => {
              switch (modalOpen) {
                case 'delete':
                  return (
                    <Layout.Column bg="navHeaderBackground" grow px py>
                      <Layout.Row grow />
                      <OpenSans.Primary size="l-20" weight="bold-700">
                        {`Are you sure?`}
                      </OpenSans.Primary>
                      <OpenSans.Primary size="l-20" weight="bold-700" py>
                        {`${rowSelected.displayName} will no longer have access to this workspace.`}
                      </OpenSans.Primary>

                      <Layout.PressableRow
                        onClick={() => startDisable()}
                        bg="destructiveLight"
                        radius={100}
                        border={[2, 'solid', 'white']}
                        py
                        px
                        align
                        center
                        grow
                      >
                        <OpenSans.Primary color="destructiveDark" size="s-16" center>
                          {`Delete Invite for ${rowSelected?.displayName}`}
                        </OpenSans.Primary>
                      </Layout.PressableRow>
                      <Spacer.Vertical />
                      <Layout.PressableRow
                        onClick={() => setModalOpen(false)}
                        bg="brandPrimaryXLight"
                        border={[1, 'solid', 'brandPrimary']}
                        py
                        px
                        radius={100}
                        align
                        center
                        grow
                      >
                        <OpenSans.Pressable onClick={() => setModalOpen(false)}>
                          {`Cancel`}
                        </OpenSans.Pressable>
                      </Layout.PressableRow>
                    </Layout.Column>
                  );
                case 'options':
                  return (
                    <Layout.Column bg="navHeaderBackground" grow px py>
                      <OpenSans.Primary weight="bold-700" size="xl-28">
                        {getTranslation('Cancel Invitation')}
                      </OpenSans.Primary>
                      <Spacer.Vertical size={12} />

                      <Layout.PressableRow
                        onClick={() => setModalOpen('delete')}
                        bg="destructiveLight"
                        radius={100}
                        border={[2, 'solid', 'white']}
                        py
                        px
                        align
                        center
                        grow
                      >
                        <OpenSans.Primary color="destructiveDark" size="s-16" center>
                          {`Delete Invite for ${rowSelected?.displayName}`}
                        </OpenSans.Primary>
                      </Layout.PressableRow>

                      <Spacer.Vertical />

                      <Layout.PressableRow
                        onClick={() => setModalOpen(false)}
                        bg="brandPrimaryXLight"
                        border={[1, 'solid', 'brandPrimary']}
                        py
                        px
                        radius={100}
                        align
                        center
                        grow
                      >
                        <OpenSans.Pressable onClick={() => setModalOpen(false)}>
                          {`Cancel`}
                        </OpenSans.Pressable>
                      </Layout.PressableRow>
                    </Layout.Column>
                  );
                default:
                  return null;
              }
            })()
          : (() => {
              switch (modalOpen) {
                case 'disable':
                  return (
                    <Layout.Column bg="navHeaderBackground" grow py px>
                      <OpenSans.Primary size="l-20" weight="bold-700">
                        {`Are you sure?`}
                      </OpenSans.Primary>
                      <OpenSans.Primary size="l-20" weight="bold-700">
                        {`${rowSelected?.displayName} will no longer have access to this workspace.`}
                      </OpenSans.Primary>
                      <Spacer.Vertical />

                      <Layout.PressableRow
                        onClick={() => startDisable()}
                        bg="destructiveLight"
                        radius={100}
                        border={[2, 'solid', 'white']}
                        py
                        px
                        align
                        center
                        grow
                      >
                        <OpenSans.Primary color="destructiveDark" size="s-16" center>
                          {`Remove ${rowSelected?.displayName}`}
                        </OpenSans.Primary>
                      </Layout.PressableRow>

                      <Spacer.Vertical />
                      <Layout.PressableRow
                        onClick={() => setModalOpen(false)}
                        bg="brandPrimaryXLight"
                        border={[1, 'solid', 'brandPrimary']}
                        py
                        px
                        radius={100}
                        align
                        center
                        grow
                      >
                        <OpenSans.Pressable onClick={() => setModalOpen(false)}>
                          {`Cancel`}
                        </OpenSans.Pressable>
                      </Layout.PressableRow>
                    </Layout.Column>
                  );
                case 'options':
                  return (
                    <Layout.Column bg="navHeaderBackground" px py>
                      <OpenSans.Primary weight="bold-700" size="xl-28">
                        {getTranslation('Change User Permissions in your Workspace')}
                      </OpenSans.Primary>
                      <Spacer.Vertical size={12} />
                      <PermissionsSelector
                        setPermission={(p) => {
                          setSelectedRowPermission(p);
                        }}
                        permission={selectedRowPermission ?? UserRoleSchema.Enum.member}
                      />
                      <Spacer.Vertical />
                      <Layout.Row grow justify="center">
                        <Layout.PressableRow
                          onClick={() => startGrant()}
                          bg="brandPrimaryXLight"
                          border={[1, 'solid', 'brandPrimary']}
                          py
                          px
                          radius={100}
                          align
                          center
                          grow
                        >
                          <OpenSans.Pressable onClick={() => startGrant()}>
                            {`Confirm`}
                          </OpenSans.Pressable>
                        </Layout.PressableRow>

                        <Spacer.Horizontal />

                        <Layout.PressableRow
                          onClick={() => setModalOpen('disable')}
                          bg="destructiveXLight"
                          radius={100}
                          border={[2, 'solid', 'white']}
                          py
                          px
                          align
                          center
                          grow
                        >
                          <OpenSans.Primary color="destructiveDark" size="s-16" center>
                            {`Disable ${rowSelected?.displayName}`}
                          </OpenSans.Primary>
                        </Layout.PressableRow>
                      </Layout.Row>
                    </Layout.Column>
                  );
                default:
                  return null;
              }
            })()}
      </Modal>
    </Layout.Column>
  );
};
