import { useCachedReports } from '@easy-expense/data-firestore-client';
import { Report } from '@easy-expense/data-schema-v2';
import { getTranslation } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import { hueToColor } from '@easy-expense/utils-shared';
import { lighten, darken } from 'polished';
import React from 'react';

import { Circle } from '../../Shape.components';
import { SearchDropdown } from '../../Shared/Dropdown/SearchDropDown.component';

export const ReportsField: React.FC<
  React.PropsWithChildren<{
    expenseKey?: string;
    onChange: (reportKeys: string[]) => void;
  }>
> = ({ expenseKey, onChange }) => {
  const reports = useCachedReports();
  const [expenseReports, setExpenseReports] = React.useState<Report[]>([]);
  const [otherReports, setOtherReports] = React.useState<Report[]>([]);

  function setReportSelected(newExpenseReports: Report[]) {
    setExpenseReports(newExpenseReports);
    const reportKeys = newExpenseReports.map((report) => report.key);
    onChange(reportKeys);
  }

  function onReportAdded(value: string) {
    const toAdd = reports.find((report) => report.key === value);
    if (toAdd) {
      const newExpenseReports = [...expenseReports, toAdd];
      const newOtherReports = otherReports.filter((report) => report.key !== value);
      setReportSelected(newExpenseReports);
      setOtherReports(newOtherReports);
    }
  }

  function onReportRemoved(value: string) {
    const toRemove = expenseReports.find((report) => report.key === value);
    if (toRemove) {
      const newExpenseReports = expenseReports.filter((report) => report.key !== value);
      const newOtherReports = [...otherReports, toRemove];
      setReportSelected(newExpenseReports);
      setOtherReports(newOtherReports);
    }
  }

  React.useEffect(() => {
    const expenseReports: Report[] = [];
    const otherReports: Report[] = [];

    reports.forEach((report) => {
      if (report.expenses.includes(expenseKey ?? '')) {
        expenseReports.push(report);
      } else {
        otherReports.push(report);
      }
    });

    setExpenseReports(expenseReports);
    onChange(expenseReports.map((report) => report.key));
    setOtherReports(otherReports);
  }, [expenseKey, reports]);

  const autoCompleteValues = otherReports
    .filter((report) => report.status !== 'Complete')
    .map((report) => ({
      key: report.key,
      value: {
        name: report.name,
        color: report.color,
      },
    }));

  return (
    <Layout.Column>
      <SearchDropdown
        header={getTranslation('Reports (optional)')}
        placeholder={getTranslation('Add to Report')}
        selectedKey={undefined}
        autoCompleteValues={autoCompleteValues}
        onSelect={(c) => {
          if (c?.key) {
            onReportAdded(c?.key);
          }
        }}
        iconicIcon="document-outline"
        error={false}
      />

      <Layout.Column>
        {expenseReports.map((report) => (
          <Layout.Column>
            <Spacer.Vertical size={8} />
            <ReportRow key={report.key} report={report} onClick={onReportRemoved} />
          </Layout.Column>
        ))}
      </Layout.Column>
    </Layout.Column>
  );
};

const ReportRow: React.FC<{
  report: Report;
  onClick: (key: string) => void;
}> = ({ report, onClick }) => {
  return (
    <Layout.Row
      style={{
        width: '100%',
        backgroundColor: lighten(0.35, `${hueToColor(report.color)}`),
        border: `1px solid ${darken(0.25, `${hueToColor(report.color)}`)}`,
      }}
      radius={6}
      px
      py={6}
      align
    >
      <Circle.Solid
        style={{ backgroundColor: darken(0.25, `${hueToColor(report.color)}`) }}
        circleSize={16}
      />
      <Spacer.Horizontal size={8} />
      <OpenSans.Primary size="s-16">{report.name}</OpenSans.Primary>
      <Spacer.Flex />
      <Layout.PressableColumn
        onClick={() => onClick(report.key)}
        style={{ background: 'none' }}
        px={4}
        py={4}
      >
        <Icon iconColor={'grayDark'} name="close" size={20} />
      </Layout.PressableColumn>
    </Layout.Row>
  );
};
