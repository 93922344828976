import {
  useCachedReportWithData,
  useCurrentWorkspace,
  firestoreWorkspaceCollectionRef,
  useWorkspaceStore,
  useCachedReports,
} from '@easy-expense/data-firestore-client';
import { entityFields } from '@easy-expense/data-firestore-shared';
import {
  ReportWithData,
  ReportWithDataSchema,
  ReportSchema,
  EntityFilter,
} from '@easy-expense/data-schema-v2';
import Data from '@easy-expense/frontend-data-layer';
import { getTranslation, useIntlStore } from '@easy-expense/intl-client';
import { Icon } from '@easy-expense/ui-shared-components';
import { theme } from '@easy-expense/ui-theme';
import { Layout, OpenSans, Spacer } from '@easy-expense/ui-web-core';
import {
  DefaultReportTemplate,
  EmptyEntityFilter,
  filterData,
  getReportPDFProps,
  renderToTemplate,
  reportHeadText,
  ReportPDFTemplateProps,
} from '@easy-expense/utils-shared';
import cuid from 'cuid';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import { DownloadReportCSVButton } from './DownloadReportCSVButton';
import { DownloadReportPDFButton } from './DownloadReportPDFButton';
import { ReportName } from './ReportName.component';
import { ReportStatusSelector } from './ReportStatusSelector.component';
import { auth } from '../../../firebase/app';
import { Button } from '../../Button.components';
import { ExpenseTable } from '../../Expense/MaterialExpenseTable.component';
import { CloseButton } from '../../Shared/CloseButton.component';
import { InsertDropdown } from '../../Shared/Dropdown/InsertableDropdown.component';
import { TripTable } from '../../Trip/TripList';
import { ReportAddTransactions } from '../ReportAddTransactions.component';
import { ReportAddTrips } from '../ReportAddTrips.component';
import { ShareReport } from '../ShareReportButton';

export const useReportPreviewData = () => {
  const tripClasses = Data.tripClasses.use();
  const vendors = Data.vendors.use();
  const categories = Data.expenseCategories.use();
  const paymentMethods = Data.paymentMethods.use();
  const locations = Data.locations.use();

  const members = useWorkspaceStore((s) => s.workspaceMembers);
  const reports = useCachedReports();
  const clients = Data.clients.use();

  const incomeCategories = Data.incomeCategories.use();

  const getReportPreview = ({
    reportWithData,
    entityFilter,
  }: {
    reportWithData: ReportWithData;
    entityFilter?: EntityFilter;
  }) => {
    const filter = entityFilter ?? EmptyEntityFilter;
    const expenses = Data.expenses.getByKeys(reportWithData.expenses);
    const incomes = Data.incomes.getByKeys(reportWithData.incomes);
    const trips = Data.trips.getByKeys(reportWithData.trips);
    return filterData({
      incomes,
      expenses,
      trips,
      reports,
      categories,
      incomeCategories,
      vendors,
      paymentMethods,
      tripClasses,
      locations,
      members,
      clients,
      filter,
    });
  };

  return { getReportPreview };
};

export const ReportDetail: React.FC<React.PropsWithChildren<{ reportKey?: string }>> = ({
  reportKey,
}) => {
  const hideTrips = Data.trips.getSize() === 0;
  const navigate = useNavigate();
  const cachedReport = useCachedReportWithData(reportKey);
  const workspace = useCurrentWorkspace();
  const { formatCurrency } = useIntlStore();
  const clients = Data.clients.use();
  const [clientName, setClientName] = React.useState<string | null>(
    cachedReport?.clientValue?.name ?? null,
  );
  const [report, setReport] = React.useState<ReportWithData | null>(cachedReport);
  const [reportChanged, setReportChanged] = React.useState<boolean>(false);
  const [isNewReport, setIsNewReport] = React.useState<boolean>(false);

  const taxRate = workspace?.settings.taxRates.find(({ tracked }) => tracked);

  const [user] = useAuthState(auth);
  const [saveURL, setSaveURL] = React.useState<string | null>(null);
  const { getReportPreview } = useReportPreviewData();

  //done so that the fetchReportSummary is not called when status is updated since it does not appear on the pdf
  const [viewAddTransactions, setViewAddTransactions] = React.useState<boolean>();
  const [viewAddTrips, setViewAddTrips] = React.useState<boolean>();

  const [reportHtml, setReportHtml] = React.useState<string>('');

  React.useEffect(() => {
    if (cachedReport) {
      setReport(cachedReport);
      setClientName(cachedReport.clientValue?.name ?? null);
      reloadReportPreview(cachedReport);
    }
  }, [cachedReport, workspace?.org]);

  React.useEffect(() => {
    if (!reportKey && !cachedReport && user) {
      const newReportWithData = ReportWithDataSchema.safeParse({
        ...entityFields('create', user?.uid),
        name: '',
        status: 'Unsent',
        expenses: [],
        incomes: [],
        trips: [],
        key: cuid(),
        tripTotal: 0,
        expenseTotal: 0,
        incomeTotal: 0,
        total: 0,
        reportExpenses: [],
        reportIncomes: [],
        reportTrips: [],
        canEdit: true,
      });

      if (newReportWithData.success) {
        setIsNewReport(true);
        setReport(newReportWithData.data);
        reloadReportPreview(newReportWithData.data);

        return;
      }

      throw `error creating a new report with data ${JSON.stringify(newReportWithData, null, 2)}`; // open the error page
    }
  }, [cachedReport, user, reportKey]);

  React.useEffect(() => {
    if (report && !viewAddTransactions && !viewAddTrips) {
      reloadReportPreview(report);
    }
  }, [viewAddTransactions, viewAddTrips]);

  const screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;

  function onDelete() {
    if (reportKey) {
      if (confirm(getTranslation('Are you sure you want to permanently delete this report?'))) {
        Data.reports.archive(reportKey);
        navigate(`/reports`);
      }
    } else {
      navigate(`/reports`);
    }
  }

  async function onSaveReport() {
    try {
      let clientKey = report?.client;
      if (clientName) {
        clientKey = Data.clients.create({
          value: {
            name: clientName,
          },
        });
      }

      /**
       * This is a hack to get around being able to set the createBy field when updating entities
       * This will be fixed in https://linear.app/easy-expense/issue/EE-3149/extend-the-report-data-type-to-include-shared-userid
       */

      const entityfields = isNewReport
        ? { ...entityFields('create', user?.uid ?? '') }
        : { ...entityFields('update', user?.uid ?? '') };

      const key = report?.key ?? cuid();
      const parsedReport = ReportSchema.partial().parse({
        ...report,
        ...entityfields,
        client: clientKey,
        key,
      });
      const workspaceCollectionRef = firestoreWorkspaceCollectionRef('report');
      workspaceCollectionRef.doc(key).set(parsedReport);

      /* hack end */

      navigate(`/reports`);
    } catch (err) {
      alert('There was an error saving the report');
    }
  }

  async function reloadReportPreview(reportToPreview: ReportWithData) {
    if (workspace === undefined) {
      return undefined;
    }
    const data = getReportPreview({ reportWithData: reportToPreview });
    const reportHtml = renderToTemplate<ReportPDFTemplateProps>(
      reportHeadText,
      DefaultReportTemplate,
      getReportPDFProps({
        data,
        attachReceipts: true,
        workspace,
        reportKey,
        isPreview: true,
        taxRate,
      }),
    );

    setReportHtml(reportHtml);
  }

  function closeReportDetail(navPath: string = '/reports') {
    if (reportChanged) {
      if (
        confirm(
          getTranslation('There are unsaved changes on this report, do you wish to discard it?'),
        )
      ) {
        navigate(navPath);
      }
    } else {
      navigate(navPath);
    }
  }

  if (!report) {
    return null;
  }

  if (viewAddTransactions) {
    return (
      <ReportAddTransactions
        onClose={() => setViewAddTransactions(false)}
        report={report}
        setReportDirty={() => setReportChanged(true)}
      />
    );
  }

  if (viewAddTrips) {
    return (
      <ReportAddTrips
        onClose={() => setViewAddTrips(false)}
        report={report}
        setReportDirty={() => setReportChanged(true)}
      />
    );
  }

  return (
    <Layout.Column style={{ width: '100%', height: '100vh' }}>
      <Layout.Row>
        {screenWidth > 1000 ? (
          <Layout.Column
            style={{
              display: 'block',
              backgroundColor: '#CFD6E0',
              overflow: 'scroll',
              height: '100vh',
              minWidth: '650px',
            }}
            px={24}
            py={20}
            className="hide-on-mobile"
          >
            <Layout.Column style={{ height: '100%' }}>
              <iframe
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  borderRadius: 8,
                  boxShadow: '0 0 16px rgba(31, 31, 31, 0.3)',
                }}
                src={'data:text/html;charset=utf-8,' + encodeURIComponent(reportHtml)}
              />
            </Layout.Column>
          </Layout.Column>
        ) : null}

        <Layout.Column
          style={{
            height: '100vh',
            boxShadow: '-8px 0 8px rgba(31, 31, 31, 0.1)',
          }}
          grow
        >
          <Layout.Column py={24} px={32} grow style={{ overflow: 'scroll', width: '100%' }}>
            <Layout.Row>
              <Spacer.Flex />
              <Layout.Column style={{ minWidth: 650, maxWidth: 1200, width: '100%' }}>
                <ReportName
                  name={report.name}
                  onChange={(name) => {
                    setReport({ ...report, name });
                    setReportChanged(true);
                  }}
                />

                <Spacer.Vertical size={16} />

                <ReportStatusSelector
                  status={report.status}
                  setStatus={(status) => {
                    setReport({ ...report, status });
                    setReportChanged(true);
                  }}
                />

                <Spacer.Vertical size={16} />

                <Layout.Row>
                  <InsertDropdown
                    header={getTranslation('Client')}
                    placeholder={getTranslation('Client Name')}
                    selectedKey={report.client}
                    autoCompleteValues={clients}
                    onSelect={(clientName) => {
                      setReportChanged(true);
                      if (clientName?.key) {
                        setReport((prev) =>
                          ReportWithDataSchema.parse({ ...prev, client: clientName.key }),
                        );

                        setClientName(clientName.value.name);
                      } else if (clientName?.value.name) {
                        setClientName(clientName.value.name);
                      }
                    }}
                    allowCreateNew
                    iconicIcon="person-circle-outline"
                    error={false}
                  />

                  <Spacer.Horizontal size={16} />

                  <ShareReport
                    createdBy={report.shared ? 'everyone' : report.createdBy}
                    onSelected={(shareWith) => {
                      if (shareWith?.key) {
                        if (shareWith.key === 'everyone') {
                          setReport({ ...report, shared: true });
                        } else {
                          setReport({ ...report, shared: false, createdBy: shareWith.key });
                        }
                        setReportChanged(true);
                      }
                    }}
                  />
                </Layout.Row>

                <Spacer.Vertical size={32} />

                <Layout.Row py style={{ width: '100%' }}>
                  <Layout.Row px border={[1, 'solid', 'primary']} radius={8} align bg="white">
                    <Icon name="trending-down-outline" size={42} color={theme.colors.primary} />
                    <Spacer.Horizontal size={12} />
                    <Layout.Column>
                      <OpenSans.Primary weight="bold-700" size="s-16">
                        {formatCurrency(Math.abs(report?.expenseTotal ?? 0))}
                      </OpenSans.Primary>
                      <OpenSans.Primary size="xs-12">{getTranslation('Expenses')}</OpenSans.Primary>
                    </Layout.Column>
                  </Layout.Row>

                  <Spacer.Horizontal size={16} />

                  {!hideTrips ? (
                    <Layout.Row px border={[1, 'solid', 'primary']} radius={8} align bg="white">
                      <Icon name="car-outline" size={42} color={theme.colors.primary} />
                      <Spacer.Horizontal size={12} />
                      <Layout.Column>
                        <OpenSans.Primary weight="bold-700" size="s-16">
                          {formatCurrency(Math.abs(report?.tripTotal ?? 0))}
                        </OpenSans.Primary>
                        <OpenSans.Primary size="xs-12">{getTranslation('Drives')}</OpenSans.Primary>
                      </Layout.Column>
                    </Layout.Row>
                  ) : null}

                  <Spacer.Flex />

                  {!isNewReport ? (
                    <Layout.Row>
                      <DownloadReportPDFButton
                        reportID={report.key}
                        saveURL={saveURL}
                        setSaveURL={setSaveURL}
                      />

                      <Spacer.Horizontal size={16} />

                      <DownloadReportCSVButton reportID={report.key} />
                    </Layout.Row>
                  ) : null}
                </Layout.Row>

                <Spacer.Vertical size={16} />

                <Layout.Row py>
                  <OpenSans.Primary size={32}>{getTranslation('Transactions')}</OpenSans.Primary>
                  <Spacer.Horizontal size={32} />

                  <Layout.PressableRow
                    onClick={() => setViewAddTransactions(true)}
                    bg="brandPrimaryXLight"
                    border={[1, 'solid', 'brandPrimary']}
                    px
                    radius={100}
                    align
                  >
                    <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
                    <Spacer.Horizontal size={8} />
                    <OpenSans.Pressable>
                      {getTranslation('Add/Remove Transactions')}
                    </OpenSans.Pressable>
                  </Layout.PressableRow>
                </Layout.Row>

                <Spacer.Vertical size={16} />

                <ExpenseTable
                  onRowClick={(expense) => navigate(`/expense/${expense}`)}
                  expenseList={report.expenses}
                  visibleColumns={['date', 'vendorValueName', 'categoryValueName', 'total']}
                  additionalOptions={{ enableTopToolbar: false }}
                />

                {!hideTrips ? (
                  <Layout.Column>
                    <Layout.Row py>
                      <OpenSans.Primary size={32}>{getTranslation('Drives')}</OpenSans.Primary>

                      <Spacer.Horizontal size={16} />

                      <Layout.PressableRow
                        onClick={() => setViewAddTrips(true)}
                        bg="brandPrimaryXLight"
                        border={[1, 'solid', 'brandPrimary']}
                        px
                        radius={100}
                        align
                      >
                        <Icon name="add-outline" size={16} color={theme.colors.brandPrimary} />
                        <Spacer.Horizontal size={8} />
                        <OpenSans.Pressable>
                          {getTranslation('Add/Remove Drives')}
                        </OpenSans.Pressable>
                      </Layout.PressableRow>
                    </Layout.Row>

                    <Spacer.Vertical size={32} />

                    <TripTable
                      onRowClick={() => {}}
                      tripList={report.trips}
                      visibleColumns={['date', 'tripClassValueName', 'trip', 'distance', 'total']}
                      additionalOptions={{ enableTopToolbar: false }}
                    />
                  </Layout.Column>
                ) : null}
              </Layout.Column>
              <Spacer.Flex />
            </Layout.Row>
          </Layout.Column>

          <Layout.Row
            style={{
              borderTop: `2px solid ${theme.colors.grayXLight}`,
            }}
            bg="white"
            px={32}
            py={24}
          >
            <Layout.PressableRow
              px
              py={8}
              bg="destructiveXLight"
              radius={100}
              border={[2, 'solid', 'white']}
              align
              onClick={onDelete}
            >
              <Icon name="trash-outline" size={20} color={theme.colors.destructiveDark} />
              <Spacer.Horizontal size={8} />
              <OpenSans.Primary color="destructiveDark" grow size="s-16">
                {getTranslation('Delete Report')}
              </OpenSans.Primary>
              <Spacer.Horizontal size={4} />
            </Layout.PressableRow>

            <Spacer.Horizontal size={16} />

            <Button.Primary
              onClick={onSaveReport}
              radius={50}
              style={{
                border: `2px solid ${theme.colors.white}`,
              }}
            >
              <OpenSans.Custom
                size={15}
                weight="bold-700"
                style={{ color: theme.colors.buttonWhite }}
              >
                {getTranslation('Save Report')}
              </OpenSans.Custom>
              <Icon
                size={15}
                color={theme.colors.buttonWhite}
                style={{ paddingLeft: 10 }}
                name="chevron-forward"
              />
            </Button.Primary>
          </Layout.Row>
        </Layout.Column>
      </Layout.Row>
      <CloseButton onClose={() => closeReportDetail()} />
    </Layout.Column>
  );
};
